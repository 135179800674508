<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <v-container
      v-if="$route.name === 'index' && $voicer.isOpenSite() === false"
      class="pa-3"
    >
      Posez vos questions dès à présent au
      <a href="tel:01 72 95 18 18">01 72 95 18 18</a> ou sur
      <a href="mailto:contact@voeux-grdf-so.fr">contact@voeux-grdf-so.fr.</a> 
    </v-container>
    <w-block name="cta" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.message_welcome_sir {
  border-radius: 6px !important;
}
</style>
