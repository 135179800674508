const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5fd795a6181eb14b08ddbda6',
    disabledComponents: [
      'wButtonLike',
      'wTagLikeCount',
      'wCardsEngagementLabels',
    ],
    showCardDescription: true,
    enableExperimentalBlocks: true,
    authMode: [
      'local'
    ],
    authLocalOptions: {
      mode: 'unique_password',
      defaultLogin: 'digital+grdfso@mediameeting.fr',

    },
    metadatas: {
      name: `Libres-échanges Sud-Ouest`,
      colorPrimary: '#70b959',
      supportEmailAddress: 'contact@voeux-grdf-so.fr',
    },
    analytics: {
      mixpanel: '1a53eb69a8fae10b8eac2c91f9184530',
      matomo: "26"
    },

    enableSplashScreen: 'wavy',
    useLTS: true,

    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '6388b8742127057bfe6fa0c5',
            i18n: {
              fr: {
                title: 'Edition 2023',
              },
            },
            heading: {
              color: '#f9ae24',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '61b9f8cc30d7ee5cdbc35f91',
            i18n: {
              fr: {
                title: 'Edition 2022 ',
              },
            },
            heading: {
              color: '#00b1ae',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '61b9f8d230d7eef725c35f92',
            i18n: {
              fr: {
                title: 'Edition 2021',
              },
            },
            heading: {
              color: '#f9ae24',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
