/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '6388b828b353bc6ca5e2619f',
          i18n: {
            fr: {
              title: 'Edition 2023',
            },
          },
          heading: {
            color: '#f9ae24',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '6193b513bb97166800a1e8b0',
          i18n: {
            fr: {
              title: 'Edition 2022',
            },
          },
          heading: {
            color: '#00b1ae',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '6193b518bb971691fea1e8b1',
          i18n: {
            fr: {
              title: 'Edition 2021',
            },
          },
          heading: {
            color: '#f9ae24',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
      ],
    },
  ],
}
